import { fetchSignInMethodsForEmail } from "firebase/auth";
import { auth } from "../config/firebase";

export const checkIfEmailExists = async (email: string) => {
  try {
    const signInMethods = await fetchSignInMethodsForEmail(auth, email);
    if (signInMethods.length > 0) {
      return true; // Email is already registered
    } else {
      return false; // Email is not registered
    }
  } catch (error) {
    console.error("Error checking email:", error);
    throw error; // Handle error (e.g., invalid email format)
  }
};
