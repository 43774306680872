import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import { auth, firestore } from "../../config/firebase";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { toast } from "react-toastify";

export const getSuperExhibitor = (id: any) => async (dispatch: any) => {
  const docRef = doc(firestore, "exhibitors", id);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    dispatch({
      type: "FETCH_SUPER_EXHIBITOR",
      payload: { docId: docSnap.id, ...docSnap.data() },
    });
  } else {
    console.log("No such document!");
  }
};

export const getAllExhibitors = (id: any) => async (dispatch: any) => {
  const q = query(
    collection(firestore, "sub_exhibitors"),
    where("exhibitorId", "==", id)
  );
  onSnapshot(q, (querySnapshot) => {
    const exhibitors: any = [];
    querySnapshot.forEach((doc) => {
      exhibitors.push({ docId: doc.id, ...doc.data() });
    });
    dispatch({
      type: "FETCH_EXHIBITORS",
      payload: exhibitors,
    });
  });
};

export const setExhibitorLoading = (val: boolean) => async (dispatch: any) => {
  dispatch({
    type: "EXHIBITOR_LOADING",
    payload: val,
  });
};

export const addExhibitor =
  (obj: any, onSuccess: any) => async (dispatch: any) => {
    dispatch(setExhibitorLoading(true));
    const q = collection(firestore, "sub_exhibitors");
    const querySnapshot = await getDocs(q);
    const emailExists = querySnapshot.docs.some(
      (doc) => doc?.data()?.companyInfo?.email === obj?.companyInfo?.email
    );
    if (emailExists) {
      dispatch(setExhibitorLoading(false));
      toast.warning("Email already registered");
      return;
    }
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        obj?.companyInfo?.email,
        "Qwertyuiop@123"
      );

      // Wait for setDoc to complete
      await setDoc(
        doc(firestore, "sub_exhibitors", userCredential.user.uid),
        obj
      );

      dispatch({
        type: "EXHIBITOR_SUCCESS",
      });
      onSuccess();
    } catch (error: any) {
      console.error("Error:", error.message);
      toast.warning(error.message || "Something went wrong");
    } finally {
      dispatch(setExhibitorLoading(false));
    }
  };

export const deleteExhibitor = (id: any) => async (dispatch: any) => {
  try {
    deleteDoc(doc(firestore, "sub_exhibitors", id)).then((res) => {
      return;
    });
  } catch (e) {}
};
