const initState = {
  organizations: [],
  orgLoader: false,
};
const organizationReducer = (state = initState, action: any) => {
  switch (action.type) {
    case "FETCH_ORGANIZATIONS":
      return {
        ...state,
        organizations: action.payload,
      };
    case "ORGANIZATION_LOADING":
      return {
        ...state,
        orgLoader: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};
export default organizationReducer;
