import React from "react";
import CustomCard from "../../components/cards/CustomCard";
import { Box, Divider, Flex } from "@chakra-ui/react";
import CustomText from "../../components/text/CustomText";
import BgGradientLines from "../../assets/images/bg-lines.png";
import GoldLine from "../../svgs/GoldLine";
import { useSelector } from "react-redux";

function BadgesStatus() {
  const { badgeTypes } = useSelector((state: any) => state.general);
  const { visitors } = useSelector((state: any) => state.visitors);

  const getBadgePercentage = (badge: string) => {
    const badgeVisitors = visitors?.filter(
      (visitor: any) =>
        visitor?.badge?.type?.toLowerCase() === badge?.toLowerCase()
    );
    if (visitors.length === 0) return "0%";
    return ((badgeVisitors.length / visitors.length) * 100).toFixed(0) + "%";
  };

  return (
    <CustomCard maxW="533px">
      <Flex gap="16px" align="center">
        <CustomText text="Badges" wrap="nowrap" color="yess.base_black" />
        <Divider bg="#F2F2F2" />
      </Flex>
      <Flex flexDir="column" gap="8px" mt="24px">
        {badgeTypes?.[0]?.types?.map((badge: any, index: number) => (
          <Flex key={index} gap="12px" align="flex-start">
            <Box maxW="95px" w="100%">
              <CustomText
                text={badge}
                color="yess.neutral700"
                size="10px"
                align="end"
                mt="3px"
              />
            </Box>
            <Flex
              w="100%"
              h="24px"
              borderRadius="4px"
              background={`url(${BgGradientLines}), rgba(242, 242, 242, 0.75)`}
              backgroundRepeat="no-repeat"
              backgroundSize="cover"
              //   backgroundPosition="right"
            >
              <Flex
                p="4px"
                bg="#e9e9e9"
                w={getBadgePercentage(badge)}
                h="24px"
                borderRadius="4px"
                zIndex="10"
              >
                <Box bg="#387DF5" borderRadius="4px" w="100%" />
              </Flex>
              <Box mt="5px" ml="-2px" zIndex="1">
                <GoldLine />
              </Box>
            </Flex>
            <Box maxW="22px" w="100%">
              <CustomText
                text={getBadgePercentage(badge)}
                color="yess.neutral700"
                size="10px"
                mt="3px"
              />
            </Box>
          </Flex>
        ))}
      </Flex>
    </CustomCard>
  );
}

export default BadgesStatus;
