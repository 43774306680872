const initState = {
  types: [],
  countries: [],
  badgeTypes: [],
};

const generalReducer = (state = initState, action: any) => {
  switch (action.type) {
    case "FETCH_TYPES":
      return {
        ...state,
        types: action.payload,
      };
    case "FETCH_COUNTRIES":
      return {
        ...state,
        countries: action.payload,
      };
    case "FETCH_BADGE_TYPES":
      return {
        ...state,
        badgeTypes: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};
export default generalReducer;
