import { Divider, Flex } from "@chakra-ui/react";
import React from "react";
import CustomCard from "../../components/cards/CustomCard";
import IconBadge from "../../svgs/IconBadge";
import CustomText from "../../components/text/CustomText";
import { useSelector } from "react-redux";

function BadgeStats() {
  const { visitors } = useSelector((state: any) => state.visitors);
  const { superExhibitor } = useSelector((state: any) => state.exhibitors);

  const totalAssigned = superExhibitor?.assignedUsers || 0;
  const activeBadges = visitors.filter(
    (visitor: any) => visitor?.badge?.status === "Active"
  )?.length;
  const pending = visitors.filter((visitor: any) => !visitor?.badge)?.length;
  const expired = visitors.filter(
    (visitor: any) => visitor?.badge?.status === "Expired"
  )?.length;

  return (
    <Flex gap="24px" align="center">
      <CustomCard>
        <Flex flexDir="column" justify="space-between" minH="102px">
          <Flex gap="16px" align="center">
            <Flex
              align="center"
              justify="center"
              h="40px"
              w="40px"
              bg={"yess.secondary_light_gray"}
              borderRadius="8px"
            >
              <IconBadge />
            </Flex>
            <CustomText text="Total Assigned" color="yess.base_black" />
          </Flex>
          <Flex gap="10px" align="center">
            <CustomText
              text={totalAssigned}
              size="32px"
              color="yess.base_black"
            />
          </Flex>
        </Flex>
      </CustomCard>
      <CustomCard>
        <Flex flexDir="column" justify="space-between" minH="102px">
          <Flex gap="16px" align="center">
            <CustomText
              text="Active Badges"
              wrap="nowrap"
              color="yess.base_black"
            />
            <Divider bg="#F2F2F2" />
          </Flex>
          <Flex gap="10px" align="center">
            <CustomText
              text={activeBadges}
              size="32px"
              color="yess.base_black"
            />
          </Flex>
        </Flex>
      </CustomCard>
      <CustomCard>
        <Flex flexDir="column" justify="space-between" minH="102px">
          <Flex gap="16px" align="center">
            <CustomText text="Pending" wrap="nowrap" color="yess.base_black" />
            <Divider bg="#F2F2F2" />
          </Flex>
          <Flex gap="10px" align="center">
            <CustomText text={pending} size="32px" color="yess.base_black" />
          </Flex>
        </Flex>
      </CustomCard>
      <CustomCard>
        <Flex flexDir="column" justify="space-between" minH="102px">
          <Flex gap="16px" align="center">
            <CustomText text="Expired" wrap="nowrap" color="yess.base_black" />
            <Divider bg="#F2F2F2" />
          </Flex>
          <Flex gap="10px" align="center">
            <CustomText text={expired} size="32px" color="yess.base_black" />
          </Flex>
        </Flex>
      </CustomCard>
    </Flex>
  );
}

// const Badge = ({ bg, color, text }: any) => {
//   return (
//     <Flex
//       align="center"
//       justify="center"
//       px="12px"
//       py="4px"
//       borderRadius="99px"
//       bg={bg || "#D5FFF5"}
//     >
//       <CustomText text={text} size="12px" color={color || "#2EC19E"} />
//     </Flex>
//   );
// };

export default BadgeStats;
