import { combineReducers } from "redux";
import exhibitorReducer from "./exhbitors.reducer";
import visitorReducer from "./visitors.reducers";
import authUser from "./auth.reducer";
import generalReducer from "./general.reducer";
import organizationReducer from "./organization.reducer";

const appReducer = combineReducers({
  exhibitors: exhibitorReducer,
  visitors: visitorReducer,
  user: authUser,
  general: generalReducer,
  organization: organizationReducer,
});
const rootReducer = (state: any, action: any) => {
  //@ts-ignore
  return appReducer(state, action);
};

export default rootReducer;
