import { Avatar, Box, Flex, Image } from "@chakra-ui/react";
import React from "react";
import InviteBg from "../../assets/images/invite-bg.png";
import WDSLogo from "../../assets/images/wds-logo.png";
import GMI from "../../assets/images/arabic-2.png";
import BadgeIcon from "../../assets/images/badge-icon.png";
import CustomText from "../../components/text/CustomText";
import QRCode from "react-qr-code";

function InviteCard({ info }: any) {
  return (
    <Flex
      flexDir="column"
      justify="space-between"
      minW="411px"
      bg="#F2F2F2"
      borderRadius="24px"
      gap="40px"
    >
      <Box>
        <Box
          pos="relative"
          bgImage={`url(${InviteBg})`}
          bgSize="cover"
          bgRepeat="no-repeat"
          bgPos="center"
          h="200px"
          borderTopRadius="24px"
        >
          <Flex p="12px 6px" justify="space-between">
            <Image src={WDSLogo} alt="wds" w="80px" h="38px" />
            <Image src={GMI} alt="wds" w="116px" h="67px" />
          </Flex>
        </Box>
        <Box
          bg="yess.secondary_gold"
          w="224px"
          h="135px"
          borderRadius="274px"
          mx="auto"
          mt="-125px"
        />
        <Flex justify="center" flexDir="column" align="center">
          <Avatar
            src={info?.personal?.avatar}
            name={info?.personalInfo?.name || "John Doe"}
            bg="yess.secondary_gold"
            border="5px solid #F2F2F2"
            boxSize="150px"
            mt="-80px"
          />
          <Avatar src={BadgeIcon} boxSize="28px" mt="-15px" />

          <CustomText
            text={info?.personalInfo?.name || "Ahmed Ali"}
            size="24px"
            weight="700"
            color="#001C70"
          />

          <CustomText
            text={info?.personalInfo?.position || info?.jobTitle || "Founder"}
            size="14px"
            color="yess.neutral700"
          />
        </Flex>
      </Box>{" "}
      <Flex justify="center">
        <QRCode
          value={
            `Name: ${info?.personalInfo?.name || "John Doe"}\n` +
            `Position: ${info?.personalInfo?.position || "Founder"}\n` +
            `Company: ${info?.companyInfo?.name || "Company Name"}\n` +
            `Email: ${info?.personalInfo?.email}
          `
          }
          bgColor="transparent"
          size={150}
        />
      </Flex>
      <Flex flexDir="column" align="center" gap="10px">
        <CustomText
          text={`Valid For ${info?.badge?.validFor}`}
          color="#ED3D4E"
          size="12px"
          family="gotham-bold"
        />
        <Flex
          h="42px"
          borderBottomRadius="24px"
          bg="#A5A1A1"
          align="center"
          justify="center"
          w="100%"
        >
          <CustomText
            text={info?.badge?.type}
            size="18px"
            color="#fff"
            weight="700"
          />
        </Flex>
      </Flex>
    </Flex>
  );
}

export default InviteCard;
