import { Flex } from "@chakra-ui/react";
import React, { useEffect } from "react";
import TotalExhibitos from "./TotalExhibitos";
import TotalVisitors from "./TotalVisitors";
import TopCountries from "./TopCountries";
import BadgesStatus from "./BadgesStats";
import Comparison from "./Comparison";
import { useDispatch } from "react-redux";
import { getAllVisitors } from "../../redux/actions/visitors.actions";
import {
  getBadgeTypes,
  getCountries,
  getTypes,
} from "../../redux/actions/general.actions";

function Dashboard() {
  const dispatch: any = useDispatch();

  useEffect(() => {
    const exhibitorId = localStorage.getItem("exhibitorId");
    dispatch(getAllVisitors(exhibitorId));
    dispatch(getCountries());
    dispatch(getBadgeTypes());
    dispatch(getTypes());
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Flex gap="24px">
        <TotalExhibitos />
        <TotalVisitors />
        <Comparison />
      </Flex>
      <Flex mt="24px" gap="24px">
        <TopCountries />
        <BadgesStatus />
      </Flex>
    </>
  );
}

export default Dashboard;
