import * as React from "react";
import { useSelector } from "react-redux";
import WorldMap from "react-svg-worldmap";

function ReactMapChart() {
  const [data, setData] = React.useState<any[]>([]);

  const { visitors } = useSelector((state: any) => state.visitors);
  const { countries } = useSelector((state: any) => state.general);

  const restructureData = () => {
    const countryVisitorMap: { [key: string]: number } = {};

    visitors.forEach((visitor: any) => {
      const countryName = visitor?.personalInfo?.personalCountry;
      if (countryVisitorMap[countryName]) {
        countryVisitorMap[countryName] += 1;
      } else {
        countryVisitorMap[countryName] = 1;
      }
    });

    const newData = countries.map((country: any) => ({
      country: country?.countryCode?.toLowerCase(),
      value: countryVisitorMap[country.name] || 0,
    }));

    setData(newData);
  };

  React.useEffect(() => {
    restructureData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visitors, countries]);

  return (
    <div className="App">
      <WorldMap color="red" value-suffix="people" size="md" data={data} />
    </div>
  );
}

export default ReactMapChart;
