import { collection, onSnapshot, query } from "firebase/firestore";
import { firestore } from "../../config/firebase";

export const getTypes = () => async (dispatch: any) => {
  const q = query(collection(firestore, "types"));
  onSnapshot(q, (querySnapshot) => {
    const types: any = [];
    querySnapshot.forEach((doc) => {
      types.push({ docId: doc.id, ...doc.data() });
    });
    dispatch({
      type: "FETCH_TYPES",
      payload: types,
    });
  });
};

export const getCountries = () => async (dispatch: any) => {
  const q = query(collection(firestore, "countries"));
  onSnapshot(q, (querySnapshot) => {
    const countries: any = [];
    querySnapshot.forEach((doc) => {
      countries.push({ docId: doc.id, ...doc.data() });
    });
    dispatch({
      type: "FETCH_COUNTRIES",
      payload: countries,
    });
  });
};

export const getBadgeTypes = () => async (dispatch: any) => {
  const q = query(collection(firestore, "badge_types"));
  onSnapshot(q, (querySnapshot) => {
    const badgeTypes: any = [];
    querySnapshot.forEach((doc) => {
      badgeTypes.push({ docId: doc.id, ...doc.data() });
    });
    dispatch({
      type: "FETCH_BADGE_TYPES",
      payload: badgeTypes,
    });
  });
};
